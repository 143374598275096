<template>
  <div>
    <headers />
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/news/index?id=' + actTab">{{
          actTab == 1 ? "新闻资讯" : "产品公告"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ details.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="title">{{ details.title }}</div>
      <div class="time">发布时间：{{ details.createTime }}</div>
      <div class="line"></div>

      <!-- <video
        class="video"
        v-if="details.video"
        muted
        preload
        ref="videoPlayer"
        @play="onPlay"
        @pause="onPause"
        controls
      >
        <source :src="details.video" type="video/mp4" />
      </video> -->

      <div class="video" v-if="details.video" style="position: relative">
        <video
          class="video"
          muted
          preload
          ref="videoPlayer"
          @play="onPlay"
          @pause="onPause"
          controls
        >
          <source :src="details.video" type="video/mp4" />
        </video>
        <img
          v-if="showBtn"
          @click="togglePlay"
          class="playIcon"
          src="@/assets/images/play.png"
        />
      </div>

      <div class="rich" v-html="details.content"></div>

      <div class="down" v-if="actTab == 2">查看下载</div>

      <div class="downText" v-if="actTab == 2" @click="downPdf">
        <img src="@/assets/images/downIcon.png" />
        <div class="textOverflowOne">{{ details.urlName }}</div>
        （{{ details.urlSize }} MB）
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import { infoView, noticeView } from "@/api/lx";
export default {
  name: "newDetails",

  data() {
    return {
      id: "",
      actTab: 1, //1是新闻2是产品
      details: {},
      showBtn: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.actTab = this.$route.query.actTab;
    if (this.actTab == 2&&!localStorage.getItem("token")) {
      this.$router.replace({
          path: "/login",
          replace: true,
        });
       return
    }
    this.getDetails();
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    })

  },
  methods: {
    onPlay() {
      // console.log("播放");
      this.showBtn = false;
    },
    onPause() {
      // console.log("暂停");
      this.showBtn = true;
    },
    togglePlay() {
      const video = this.$refs.videoPlayer;
      video.play();
    },
    getDetails() {
      if (this.actTab == 1) {
        infoView(this.id).then((res) => {
          this.details = res.data;
        });
      } else {
        noticeView(this.id).then((res) => {
          this.details = res.data;
        });
      }
    },

    downPdf() {
      // const link = document.createElement('a');
      // link.href = this.details.url;
      // link.download = 'example';
      // document.body.appendChild(link);
      // link.click();

      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.details.url, true); // url 文件的完整地址 http:XXX
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          const res = xhr.response;
          const link = document.createElement("a");
          link.style.display = "none";
          const url = window.URL.createObjectURL(res);
          link.href = url;
          link.setAttribute("download", `${this.details.urlName}`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      };
      xhr.send();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 1400px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 20px;
  padding: 40px 50px;
  box-sizing: border-box;
  margin-bottom: 168px;
  min-height: calc(100vh - 358px);
}
.title {
  width: 100%;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 30px;
  color: #00161a;
}
.time {
  width: 100%;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #525f66;
  margin-top: 20px;
}
.line {
  width: 100%;
  height: 1px;
  background: #e6edf0;
  margin-top: 20px;
}
.video {
  width: 100%;
  height: 731px;
  margin-top: 20px;
  background-color: #00161a;
}
.rich {
  width: 100%;
  margin-top: 40px;
  /deep/img {
    max-width: 100%;
  }
}
.down {
  width: 1300px;
  height: 66px;
  background: #e6fbf9;
  margin-top: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 22px;
  color: #00d8c3;
  line-height: 66px;
  padding-left: 30px;
  box-sizing: border-box;
}
.downText {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #00d8c3;
  line-height: 30px;
  cursor: pointer;
  img {
    width: 20px;
    height: 22px;
    display: block;
    margin-right: 8px;
    margin-top: -10px;
  }
}
.playIcon {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 304px;
  left: 588px;
  cursor: pointer;
}
</style>